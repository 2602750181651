import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/_components/assets/icon-shipping.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/_components/assets/img-0.jpg");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/_components/assets/img-1.jpg");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/_components/assets/img-2.jpg");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/_components/assets/img-3.jpg");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/_components/assets/img-4.jpg");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/_components/assets/noImage.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/_components/assets/powermatic-robot.svg");
;
import(/* webpackMode: "eager", webpackExports: ["BaseCarousel"] */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/_components/Carousel/BaseCarousel.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ChatNowButton"] */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/_components/ChatNowButton/ChatNowButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ContactButtons"] */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/_components/ContactButtons/ContactButtons.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/_components/LocationCard/assets/map-asia.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/_components/LocationCard/assets/map-california.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/_components/LocationCard/assets/map-texas.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/abb-group.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/banner.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/cgi-motion.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/ckd-pneumatics.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/conta-clip.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/dart-controls.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/douloi-automation.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/eaton.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/edwards-signaling.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/fabco.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/festo.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/finder.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/gefran.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/hiwin.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/hms-networks.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/iboco.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/icotek.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/ilme.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/integra-enclosures.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/macron-dynamics.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/mayr.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/nbk.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/nidec.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/nk-technologies.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/novanta-ims.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/nsk.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/nvent.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/panasonic.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/pilz.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/pizzato.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/pneumatic-cylinders-couplers.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/puls.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/red-lion.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/rw-couplings.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/sab.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/schmalz.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/schneider-electric.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/sealcon.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/telemecanique-sensors.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/turck.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/unitronics.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/wago.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/weintek.png");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/assets/wieland.png");
;
import(/* webpackMode: "eager", webpackExports: ["MoreAuthorizedBrands"] */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/AuthorizedBrands/MoreAuthorizedBrands.tsx");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/HomePageMore/assets/bg.jpeg");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/LargeHeroBanner/assets/icon-chart.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/LargeHeroBanner/assets/icon-person.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/LargeHeroBanner/assets/icon-truck.svg");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/LargeHeroBanner/assets/largeHeroBanner.jpg");
;
import(/* webpackMode: "eager", webpackExports: ["SearchPartsButton"] */ "/tmp/build_4f518bc5/apps/powermatic/src/app/(www)/(homepage)/_components/LargeHeroBanner/SearchPartsButton.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/tmp/build_4f518bc5/node_modules/@mui/icons-material/ArrowForward.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/tmp/build_4f518bc5/node_modules/@mui/icons-material/ArrowRightAlt.js");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/tmp/build_4f518bc5/node_modules/@mui/icons-material/CheckCircle.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/node_modules/@mui/material/Box/index.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/node_modules/@mui/material/Button/index.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/node_modules/@mui/material/Card/index.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/node_modules/@mui/material/CardActionArea/index.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/node_modules/@mui/material/Container/index.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/node_modules/@mui/material/Grid/index.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/node_modules/@mui/material/Link/index.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/node_modules/@mui/material/Stack/index.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/node_modules/@mui/material/Typography/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_4f518bc5/node_modules/@mui/utils/useControlled/useControlled.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_4f518bc5/node_modules/@mui/utils/useEnhancedEffect/useEnhancedEffect.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_4f518bc5/node_modules/@mui/utils/useEventCallback/useEventCallback.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_4f518bc5/node_modules/@mui/utils/useForkRef/useForkRef.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_4f518bc5/node_modules/@mui/utils/useId/useId.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/node_modules/@mui/utils/useIsFocusVisible/useIsFocusVisible.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_4f518bc5/node_modules/@mui/utils/useLazyRef/useLazyRef.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_4f518bc5/node_modules/@mui/utils/useOnMount/useOnMount.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_4f518bc5/node_modules/@mui/utils/usePreviousProps/usePreviousProps.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/node_modules/@mui/utils/useTimeout/useTimeout.js");
;
import(/* webpackMode: "eager" */ "/tmp/build_4f518bc5/node_modules/next/dist/client/image-component.js");
